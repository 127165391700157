import { Breadcrumb, IBreadcrumbItem, IStackTokens, Stack, Text } from "@fluentui/react";
import { baseUrl } from "api/config";
import { UserType } from "authentication";
import { AuthStateContext } from "authentication/useAuthContext";
import Axios from "axios";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ErrorDialog } from "shared/components";
import { useError } from "shared/hooks";
import { useImmer } from "use-immer";
import { useStyles } from "./Home.styles";
import { IDashboard, IHomeProps } from "./Home.types";

const sectionStackTokens: IStackTokens = { childrenGap: 20 };
const verticalStackTokens: IStackTokens = { childrenGap: 20 };
const horizontalGapStackTokens: IStackTokens = {
	childrenGap: 12,
};

export const Home: React.FC<IHomeProps> = (props) => {
	const history = useHistory();
	const classes = useStyles();
	const authState = useContext(AuthStateContext);
	const { user } = authState;
	const { roleId, userType } = user;
	const [dashboard, setDashboard] = useImmer<IDashboard>({
		totalBIP: 0,
		totalClient: 0,
		totalReport: 0,
	});

	const itemsWithHref: IBreadcrumbItem[] = [{ text: "Home", key: "f3" }];
	const { errorstate, onErrorClose, onError } = useError();
	useEffect(() => {
		let requestURL = baseUrl + "Home/Admin";
		if (userType === UserType.BIP) requestURL = baseUrl + `Home/BIP/${user.userId}`;
		Axios.get(requestURL)
			.then((res) => {
				setDashboard((draft) => {
					return res.data;
				});
			})
			.catch((err) => {
				onError(err.response.data);
			});
	}, [onError, userType, setDashboard, user.userId]);

	return (
		<div className="row">
			<div className="col-md-3">
				<div
					className="d-flex dashboard-card justify-content-between p-4"
					onClick={() => {
						history.push(`client`);
					}}
				>
					<div className="col-md-8 p-2">
						<div className="title">Total Client</div>
						<div className="count">{dashboard.totalClient}</div>
					</div>
					<div className="card-icon">
						<i className="fas fa-user fa-3x"></i>
					</div>
				</div>
			</div>
			{userType === UserType.Admin && (
				<div className="col-md-3">
					<div
						className="d-flex dashboard-card justify-content-between p-4"
						onClick={() => {
							history.push(`bipuser`);
						}}
					>
						<div className="col-md-8 p-2">
							<div className="title">Total Coach</div>
							<div className="count">{dashboard.totalBIP}</div>
						</div>
						<div className="card-icon">
							<i className="fas fa-user fa-3x"></i>
						</div>
					</div>
				</div>
			)}

			<div className="col-md-3">
				<div className="d-flex dashboard-card justify-content-between p-4">
					<div className="col-md-8 p-2">
						<div className="title">Total Reports</div>
						<div className="count">{dashboard.totalReport}</div>
					</div>
					<div className="card-icon">
						<i className="fas fa-file fa-3x"></i>
					</div>
				</div>
			</div>
		</div>
	);

	// return (
	// 	<Stack className={classes.root}>
	// 		<Stack tokens={sectionStackTokens}>
	// 			<Stack horizontal grow={12}>
	// 				<Stack.Item grow={10} align="start">
	// 					<Breadcrumb
	// 						items={itemsWithHref}
	// 						ariaLabel="Breadcrumb with items rendered as links"
	// 						overflowAriaLabel="More links"
	// 					/>
	// 				</Stack.Item>
	// 			</Stack>
	// 			<Stack>
	// 				<ErrorDialog show={errorstate.visible} onClose={onErrorClose} />
	// 			</Stack>
	// 			<Stack
	// 				horizontal
	// 				onClick={() => {
	// 					history.push(`client`);
	// 				}}
	// 				grow={12}
	// 				tokens={horizontalGapStackTokens}
	// 			>
	// 				<Stack horizontal className={classes.cardBlock}>
	// 					<Stack tokens={verticalStackTokens} grow={11}>
	// 						<Stack.Item>
	// 							<Text variant={"medium"}>Total Client</Text>
	// 						</Stack.Item>
	// 						<Stack.Item>
	// 							<Text variant={"xLarge"}>{dashboard.totalClient}</Text>
	// 						</Stack.Item>
	// 					</Stack>
	// 					<Stack grow={1}>
	// 						<svg
	// 							xmlns="http://www.w3.org/2000/svg"
	// 							width="73"
	// 							height="73"
	// 							viewBox="0 0 73 73"
	// 						>
	// 							<rect
	// 								id="Rectangle_142979"
	// 								data-name="Rectangle 142979"
	// 								width="73"
	// 								height="73"
	// 								rx="21"
	// 								fill="#c4d4ff"
	// 							/>
	// 						</svg>
	// 					</Stack>
	// 				</Stack>
	// 				<Stack horizontal className={classes.cardBlock}>
	// 					<Stack tokens={verticalStackTokens} grow={11}>
	// 						<Stack.Item>
	// 							<Text variant={"medium"}>Report</Text>
	// 						</Stack.Item>
	// 						<Stack.Item>
	// 							<Text variant={"xLarge"}>{dashboard.totalReport}</Text>
	// 						</Stack.Item>
	// 					</Stack>
	// 					<Stack grow={1}>
	// 						<svg
	// 							xmlns="http://www.w3.org/2000/svg"
	// 							width="73"
	// 							height="73"
	// 							viewBox="0 0 73 73"
	// 						>
	// 							<rect
	// 								id="Rectangle_142979"
	// 								data-name="Rectangle 142979"
	// 								width="73"
	// 								height="73"
	// 								rx="21"
	// 								fill="#c4d4ff"
	// 							/>
	// 						</svg>
	// 					</Stack>
	// 				</Stack>
	// 				{userType === UserType.Admin ? (
	// 					<Stack
	// 						onClick={() => {
	// 							history.push(`bip`);
	// 						}}
	// 						horizontal
	// 						className={classes.cardBlock}
	// 					>
	// 						<Stack tokens={verticalStackTokens} grow={11}>
	// 							<Stack.Item>
	// 								<Text variant={"medium"}>Total Bip User</Text>
	// 							</Stack.Item>
	// 							<Stack.Item>
	// 								<Text variant={"xLarge"}>{dashboard.totalBIP}</Text>
	// 							</Stack.Item>
	// 						</Stack>
	// 						<Stack grow={1}>
	// 							<svg
	// 								xmlns="http://www.w3.org/2000/svg"
	// 								width="73"
	// 								height="73"
	// 								viewBox="0 0 73 73"
	// 							>
	// 								<rect
	// 									id="Rectangle_142979"
	// 									data-name="Rectangle 142979"
	// 									width="73"
	// 									height="73"
	// 									rx="21"
	// 									fill="#c4d4ff"
	// 								/>
	// 							</svg>
	// 						</Stack>
	// 					</Stack>
	// 				) : null}
	// 			</Stack>
	// 			<Stack horizontal grow={12} tokens={horizontalGapStackTokens}>
	// 				<Text variant={"xLarge"}>Notifications</Text>
	// 			</Stack>

	{
		/* 
				<Stack horizontal grow={12} className={classes.card}>
					<Stack className={classes.persona}>
						<Persona
							{...personaWithInitials}
							initialsColor={PersonaInitialsColor.lightBlue}
							text=""
							size={PersonaSize.size72}
						/>
					</Stack>
					<Stack grow={3}>
						<Stack.Item>
							<Text variant={"xLarge"}>Harry Potter</Text>
						</Stack.Item>
						<Stack.Item>
							<Text variant={"medium"}>Created new client GCOMM</Text>
						</Stack.Item>
					</Stack>
				</Stack>
				<Stack horizontal grow={12} className={classes.card}>
					<Stack className={classes.persona}>
						<Persona
							{...personaWithInitials}
							initialsColor={PersonaInitialsColor.lightBlue}
							text=""
							size={PersonaSize.size72}
						/>
					</Stack>
					<Stack grow={3}>
						<Stack.Item>
							<Text variant={"xLarge"}>Harry Potter</Text>
						</Stack.Item>
						<Stack.Item>
							<Text variant={"medium"}>Created new client GCOMM</Text>
						</Stack.Item>
					</Stack>
				</Stack>
			 */
	}
	// 		</Stack>
	// 	</Stack>
	// );
};
