import {
	IImageProps,
	ImageFit,
	IStackTokens,
	PrimaryButton,
	Stack,
	Image,
	TextField,
	Text,
	Link,
	Spinner,
	LinkBase,
} from "@fluentui/react";
import {
	Login,
	onChangeEmail,
	onChangePassword,
	requiredValidation,
	UserAuthDispatcher,
} from "authentication/auth.action";
import { AuthStateContext } from "authentication/useAuthContext";
import { Input } from "client/components/Controls/Input";
import React, { useContext, useState } from "react";
import { ISignInProps } from ".";
import { ForgotPassword } from "../../client/pages/ForgotPassword";
import { useStyles } from "./SignIn.styles";
import { AvForm } from "availity-reactstrap-validation";

const verticalGapStackTokens: IStackTokens = {
	childrenGap: 10,
	padding: 10,
};

export const SignIn: React.FC<ISignInProps> = (props) => {
	const styles = useStyles();
	const [toggleForgot, setToggleForgot] = useState(false);
	const userAuthDispatcher = UserAuthDispatcher();
	const authState = useContext(AuthStateContext);
	const { credential } = authState;
	const { emailAddress, password, emailAddressErrorMessage, passwordErrorMessage, errorMessage } =
		credential;

	const imageProps: IImageProps = {
		src: "/Group63267@2x.png",
		imageFit: ImageFit.cover,
		maximizeFrame: true,
	};

	const toggleForgotClick = (
		event: React.MouseEvent<
			HTMLElement | HTMLAnchorElement | HTMLButtonElement | LinkBase,
			MouseEvent
		>,
	) => {
		console.log("Clicked");

		setToggleForgot(!toggleForgot);
	};

	const logIn = () => {
		if (emailAddress && password) {
			Login(userAuthDispatcher, credential);
		} else {
			requiredValidation(userAuthDispatcher);
		}
	};

	const onEmailChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string,
	) => {
		onChangeEmail(userAuthDispatcher, newValue);
	};

	const onPasswordChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
		newValue?: string,
	) => {
		onChangePassword(userAuthDispatcher, newValue);
	};

	return (
		<>
			<section className="authentication">
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-4">
							<div className="auth-form">
								<h1 className="logo">
									<a href="index.html">
										<img src="/images/pd_logo.png" />
									</a>
								</h1>
								{toggleForgot === true ? (
									<ForgotPassword toggleClick={toggleForgotClick} />
								) : (
									<>
										<h3 className="head-style-01">Log in to your Account</h3>
										<AvForm className="base-form" onValidSubmit={logIn}>
											<Input
												type="email"
												name="email"
												label="Email"
												required="true"
												autoComplete="off"
												value={emailAddress}
												onChange={onEmailChange}
											/>
											<Input
												type="password"
												name="password"
												label="Password"
												required="true"
												value={password}
												onChange={onPasswordChange}
											/>
											<div className="form-forgot">
												<div className="checkbox">
													<input
														type="checkbox"
														id="remember"
														name="remember"
														defaultValue="remember"
													/>
													<label className="checkbox" htmlFor="remember">
														Remember Me
													</label>
												</div>
												<a onClick={toggleForgotClick} className="link">
													Forgot password?
												</a>
											</div>
											<button type="submit" className="primary-button">
												LOGIN {authState.isBusy && <Spinner />}
											</button>
											{/* <div className="signup-link">
												No account ? <a href="signup.html">Sign Up</a>
											</div> */}
										</AvForm>
									</>
								)}
							</div>
						</div>
						<div className="col-sm-8 auth-bg"></div>
					</div>
				</div>
			</section>
		</>
	);

	return (
		<Stack horizontal className={styles.root}>
			<Stack className={styles.login} grow={1}>
				<Stack className={styles.loginInnerConent} tokens={verticalGapStackTokens}>
					<Stack.Item>
						<img src="/profi_logo.png" alt="Profitune Logo"></img>
					</Stack.Item>

					{toggleForgot === true ? (
						<ForgotPassword toggleClick={toggleForgotClick} />
					) : (
						<>
							<Stack.Item>
								<Text variant={"xxLarge"}>Login in to your account</Text>
							</Stack.Item>
							{errorMessage && (
								<Stack.Item>
									<span style={{ color: "red" }}>{errorMessage}</span>
								</Stack.Item>
							)}
							<Stack.Item>
								<TextField
									type={"email"}
									value={emailAddress}
									onChange={onEmailChange}
									label="Email"
									errorMessage={emailAddressErrorMessage}
								/>
							</Stack.Item>
							<Stack.Item>
								<TextField
									type={"password"}
									value={password}
									label="Password"
									onChange={onPasswordChange}
									errorMessage={passwordErrorMessage}
								/>
							</Stack.Item>
							<Stack horizontal horizontalAlign="end">
								<Stack.Item align={"end"}>
									<Link onClick={toggleForgotClick}>Forgot password?</Link>
								</Stack.Item>
							</Stack>
							<Stack.Item>
								<PrimaryButton
									style={{ width: "100%" }}
									text="Login"
									disabled={authState.isBusy}
									onClick={logIn}
								/>
							</Stack.Item>
							{authState.isBusy && (
								<Stack.Item>
									<Spinner
										label="Signing In..."
										ariaLive="assertive"
										labelPosition="right"
									/>
								</Stack.Item>
							)}
						</>
					)}
				</Stack>
			</Stack>
			<Stack grow={8}>
				<Image
					{...imageProps}
					alt='Example of the image fit value "center" on an image larger than the frame.'
				/>
			</Stack>
		</Stack>
	);
};
